import React from "react";

/**
 *
 * @returns {number} fontSize
 */
const useFontSize = (): {
  fontSize: number;
} => {
  const [fontSize, setFontSize] = React.useState<number>(16); // Initial font size

  const adjustFontSize = () => {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    // You can adjust these coefficients as needed
    const newSize = Math.min(windowWidth / 5, windowHeight / 5);
    setFontSize(newSize);
  };

  React.useEffect(() => {
    // Call the function initially and add an event listener for window resize
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return { fontSize };
};

export default useFontSize;
