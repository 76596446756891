import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import MainPageWrapper from "../components/MainPageWrapper";

const NotFoundPage: FC = () => (
  <MainPageWrapper>
    <Container>
      <Row>
        <Col
          sm={12}
          className="d-flex justify-content-center align-items-center mt-5 mb-2"
        >
          Sorry, the page you are looking for could not be found
        </Col>
        <Col
          sm={12}
          className="d-flex justify-content-center align-items-center my-4"
        >
          You can return to the home page by clicking the button below
        </Col>
        <Col
          sm={12}
          className="d-flex justify-content-center  align-items-center my-3"
        >
          <Link to="/" className="btn btn-primary">
            Home
          </Link>
        </Col>
      </Row>
    </Container>
  </MainPageWrapper>
);

export default NotFoundPage;
