import { useEffect, useState } from "react";

const useMobileSize = () => {
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth > 768);

  const iconSize = isDesktop ? 30 : 20;
  const buttonSize: "lg" | "sm" | undefined = isDesktop ? "lg" : "sm";
  const buttonPadding = isDesktop ? "p-1" : "p-0 ";
  const buttonMargin = isDesktop ? "m-1" : "m-0 ";
  const buttonFontSize = isDesktop ? "text-lg" : "text-sm";
  const buttonWidth = isDesktop ? "w-24" : "w-16";
  const buttonHeight = isDesktop ? "h-12" : "h-8";
  const buttonBorderRadius = isDesktop ? "rounded-lg" : "rounded-md";
  const buttonShadow = isDesktop ? "shadow-lg" : "shadow-md";
  const buttonHoverShadow = isDesktop ? "hover:shadow-xl" : "hover:shadow-lg";

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    isDesktop,
    iconSize,
    buttonSize,
    buttonPadding,
    buttonMargin,
    buttonFontSize,
    buttonWidth,
    buttonHeight,
    buttonBorderRadius,
    buttonShadow,
    buttonHoverShadow,
    setIsDesktop,
  };
};

export default useMobileSize;
