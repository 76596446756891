import { FC } from "react";
import UnderConstruction from "../../components/UnderConstruction";
import TimerCard from "../../components/Cards/TimerCard";
import { timerTabsData } from "../../configs/tabsValues";
import MainPageWrapper from "../../components/MainPageWrapper";
import { Col, Row } from "react-bootstrap";

const boardOffers = [
  "Score",
  "Fouls",
  "Extensions",
  "Shot Timer",
  "Break Timer",
  "Time Out Timer",
];
const TimerPage: FC = () => {
  const enablePage = true;

  if (!enablePage) {
    return <UnderConstruction />;
  }

  return (
    <MainPageWrapper>
      <h4 className="text-center my-2">
        Using our Game Boards you can have a{" "}
        <strong>Professional game experience </strong>
      </h4>
      <h4 className="text-center mt-4">
        With our Game Boards you can keep everything in one place like:
      </h4>
      <ul>
        {boardOffers.map((offer: string) => {
          return (
            <h5 key={offer}>
              <li>{offer}</li>
            </h5>
          );
        })}
      </ul>

      <h5 className="my-4 text">Choose the Timer you want to use today!</h5>

      <Row className="align-center">
        {timerTabsData.map((tab, index) => (
          <Col md={6} lg={4} key={index}>
            <TimerCard
              path={tab.path}
              title={tab.title}
              imageUrl={tab.imagePath}
              footerText={tab.footerText}
            />
          </Col>
        ))}
      </Row>
    </MainPageWrapper>
  );
};

export default TimerPage;
