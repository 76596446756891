import React, { FC, useEffect } from "react";
import { Container } from "react-bootstrap";

interface MainPageWrapperPropsTypes {
  children: React.ReactNode;
  title?: string;
}

const MainPageWrapper: FC<MainPageWrapperPropsTypes> = ({
  children,
  title,
}) => {
  useEffect(() => {
    if (!title) document.title = "PoolInfo.net";
    else document.title = `${title} | PoolInfo.net`;
  }, []);

  return (
    <>
      <Container fluid className="main-page-wrapper">
        {/* <ErrorPopup /> */}
        {children}
      </Container>
    </>
  );
};

export default MainPageWrapper;
