export const forumTabsData = [
  { name: "8-Ball", content: "8-Ball" },
  { name: "9-Ball", content: "9-Ball" },
  { name: "10-Ball", content: "10-Ball" },
  { name: "14.1", content: "14.1" },
  { name: "Straight", content: "Straight Pool" },
  { name: "One Pocket", content: "One Pocket" },
  { name: "15-Ball", content: "15-Ball" },
  { name: "Carom", content: "Carom" },
  { name: "Snooker", content: "Snooker" },
  { name: "Speed", content: "Speed Billiards" },
  { name: "Pyramid", content: "Pyramid" },
  { name: "Banks", content: "Banks" },
  { name: "Bumper", content: "Bumper Pool" },
];

export const tournamentTabsData = [
  {
    name: "Pool",
    content: "Pool tournaments",
    path: "/tournaments/pool-tournaments",
  },
  {
    name: "Carom",
    content: "Carom tournaments",
    path: "/tournaments/carom-tournaments",
  },
  {
    name: "Snooker",
    content: "Snooker tournaments",
    path: "/tournaments/snooker-tournaments",
  },
];

export const timerTabsData = [
  {
    name: "Pool",
    content: "Pool Timer",
    path: "/timer/pool-timer",
    title: "POOL",
    imagePath: "/images/pool/mosconi.jpg",
    footerText: "Play Pool like a PRO with Timer",
  },
  {
    name: "Carom",
    content: "Carom Timer",
    path: "/timer/carom-timer",
    title: "CAROM",
    imagePath: "/images/pool/carom.jpg",
    footerText: "Play Carom like a PRO with Timer",
  },
  {
    name: "Snooker",
    content: "Snooker Timer",
    path: "/timer/snooker-timer",
    title: "SNOOKER",
    imagePath: "/images/snooker/snooker.jpg",
    footerText: "Play Snooker like a PRO with Timer",
  },
];
export const people = [
  { name: "Players", content: "Amateurs", path: "/people/players" },
  {
    name: "PRO-Players",
    content: "Professional Players",
    path: "/people/pro-players",
  },
  { name: "Instructors", content: "Instructors", path: "/people/instructors" },
  { name: "Managers", content: "Managers", path: "/people/managers" },
  { name: "Referees", content: "Referees", path: "/people/referees" },
  {
    name: "Hall Of Fame",
    content: "Hall Of Fames",
    path: "/people/hall-of-fame",
  },
];

export const companies = [
  {
    name: "Manufactures",
    content: "Manufactures",
    path: "/companies/manufactures",
  },
  { name: "Resellers", content: "Resellers", path: "/companies/resellers" },
  {
    name: "Sponsors",
    content: "Sponsor providers",
    path: "/companies/sponsors",
  },
  { name: "Pool Hals", content: "Pool Hals", path: "/companies/pool-hals" },
];
