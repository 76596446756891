import React, { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import { useTimer } from "react-timer-hook";

import { PlayCircle, PauseCircle } from "react-bootstrap-icons";
import useMobileSize from "../../hooks/useMobileSizes";

interface TimeOutTimerProps {
  mins: number;
  secs: number;
  player: number;
}

const TimeOutTimer: FC<TimeOutTimerProps> = ({ mins, secs, player }) => {
  const totalTime = new Date();
  totalTime.setSeconds(totalTime.getSeconds() + mins * 60 + secs);

  const { buttonPadding, iconSize, buttonSize } = useMobileSize();

  const { seconds, minutes, isRunning, start, pause, resume, restart } =
    useTimer({
      expiryTimestamp: totalTime,
      onExpire: () => {
        console.log("expired");
      },
    });

  const [paused, setPaused] = useState<boolean>(true);

  const handlePause = () => {
    if (paused) {
      resume();
      setPaused(false);
    } else {
      pause();
      setPaused(true);
    }
  };

  useEffect(() => {
    pause();
  }, []);

  const timeRemaining = minutes * 60 + seconds;

  const status =
    timeRemaining === 0
      ? "red"
      : paused
      ? "grey"
      : timeRemaining > 60
      ? "green"
      : timeRemaining > 0
      ? "orange"
      : "red";

  return (
    <>
      <div
        className="TimeOutTimer-Column shadow"
        style={{
          backgroundColor: paused ? "rgb(255, 255, 255)" : "red",
        }}
      >
        <div className="TimeOutTimer-Row">
          <h5
            className={`${!paused && "bg-red"} fw-bold m-0 p-0 board-row-title`}
          >
            TIMEOUT
          </h5>

          <h5 className={`bg-${paused ? "success" : "red"} timeout-text`}>
            {`${minutes}:${seconds > 9 ? seconds : `0${seconds}`}`}
          </h5>

          <Button
            onClick={handlePause}
            variant="light-outline"
            size={buttonSize}
            className="buttonIn"
          >
            {!paused ? (
              <PauseCircle size={iconSize} />
            ) : (
              <PlayCircle size={iconSize} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default TimeOutTimer;
