import React, { FC } from "react";
import MainPageWrapper from "../components/MainPageWrapper";

const HomePage: FC = () => {
  return (
    <MainPageWrapper>
      <div>
        <h5>
          With our&nbsp;
          <strong>Shot timers&nbsp;</strong>
          you can have a&nbsp;
          <strong>Professional FULL Game Board for&nbsp;</strong>
          <strong>FREE</strong>!
        </h5>
      </div>
    </MainPageWrapper>
  );
};

export default HomePage;
