import React, { FC, useState } from "react";
import TimeRenderer from "./TimeRenderer";
import { Button } from "react-bootstrap";
import CustomInput from "../Inputs/CustomInput";
import Form from "react-bootstrap/Form";
import { HouseDoorFill } from "react-bootstrap-icons";

const PoolTimerInitializer: FC = () => {
  const [shotTimeInitial, setShotTimeInitial] = useState<number>(30);
  const [brakeTimeInitial, setBrakeTimeInitial] = useState<number>(60);
  const [extensionTimeInitial, setExtensionTimeInitial] = useState<number>(30);

  const [initialTime, setInitialTime] =
    React.useState<number>(brakeTimeInitial);

  const [extensionLimit, setExtensionLimit] = useState<number>(1);
  const [foulsLimit, setFoulsLimit] = useState<number>(3);

  const [startTimer, setStartTimer] = useState<boolean>(false);

  const [enableExtension, setEnableExtension] = useState<boolean>(true);

  const [enableFouls, setEnableFouls] = useState<boolean>(true);

  const [enableScore, setEnableScore] = useState<boolean>(true);

  const [scoreLimit, setScoreLimit] = useState<number>(9);

  const [enableShotTime, setEnableShotTime] = useState<boolean>(true);
  const [enableTimeOut, setEnableTimeOut] = useState<boolean>(true);
  const [timeOutMinutes, setTimeOutMinutes] = useState<number>(5);
  const [timeOutSeconds, setTimeOutSeconds] = useState<number>(0);

  const doNothing = () => {
    return;
  };

  const fieldsArray = [
    {
      id: "brakeTimeInitial",
      label: "Brake Time",
      value: brakeTimeInitial,
      setValue: setBrakeTimeInitial,
    },
    {
      id: "shotTimeInitial",
      label: "Shot Time",
      value: shotTimeInitial,
      setValue: setShotTimeInitial,
    },
  ];

  const shotTime = new Date();
  shotTime.setSeconds(shotTime.getSeconds() + initialTime);

  const mapTimeFields = () => {
    return (
      <div
        className={`PoolTimerInitializer-OuterContainer shadow ${
          !enableShotTime && "wrapper-disabled"
        }`}
      >
        <div className="PoolTimerInitializer-header">
          <div className="PoolTimerInitializer-SpacingRow">
            <div className="fw-bold me-2">Shot Time Setup</div>
          </div>
          <div className="PoolTimerInitializer-SpacingRow">
            <Form>
              <Form.Check
                type="switch"
                className="custom-switch"
                id="custom-switch"
                checked={enableShotTime}
                onChange={() => setEnableShotTime(!enableShotTime)}
              />
            </Form>
          </div>
        </div>

        <hr />

        <label htmlFor="brakeTimeInitial">Break Time &#40;seconds&#41; </label>

        <div className="PoolTimerInitializer-SpacingRow">
          <Button
            disabled={!enableShotTime}
            onClick={() => {
              if (brakeTimeInitial > 10)
                setBrakeTimeInitial((previousValue) => previousValue - 10);
            }}
          >
            -10
          </Button>
          <Button
            disabled={!enableShotTime}
            onClick={() => {
              if (brakeTimeInitial > 1)
                setBrakeTimeInitial((previousValue) => previousValue - 1);
            }}
          >
            -1
          </Button>

          <input
            type="number"
            className="PoolTimerInitializer-Input"
            pattern="[0-9]*"
            id={"brakeTimeInitial"}
            value={brakeTimeInitial}
            disabled={!enableShotTime}
            onChange={doNothing}
          />
          <Button
            disabled={!enableShotTime}
            onClick={() => {
              if (brakeTimeInitial < 50)
                setBrakeTimeInitial((previousValue) => previousValue + 1);
            }}
          >
            +1
          </Button>
          <Button
            disabled={!enableShotTime}
            onClick={() =>
              setBrakeTimeInitial((previousValue) => previousValue + 10)
            }
          >
            +10
          </Button>
        </div>

        <hr />

        <label htmlFor="shotTimeInitial">Shot Time &#40;seconds&#41; </label>

        <div className="PoolTimerInitializer-SpacingRow">
          <Button
            disabled={!enableShotTime}
            onClick={() => {
              if (shotTimeInitial > 10)
                setShotTimeInitial((previousValue) => previousValue - 10);
            }}
          >
            -10
          </Button>
          <Button
            disabled={!enableShotTime}
            onClick={() => {
              if (shotTimeInitial > 1)
                setShotTimeInitial((previousValue) => previousValue - 1);
            }}
          >
            -1
          </Button>

          <input
            disabled={!enableShotTime}
            className="PoolTimerInitializer-Input"
            type="number"
            pattern="[0-9]*"
            id={"shotTimeInitial"}
            value={shotTimeInitial}
            onChange={doNothing}
          />
          <Button
            disabled={!enableShotTime}
            onClick={() => {
              if (shotTimeInitial < 50)
                setShotTimeInitial((previousValue) => previousValue + 1);
            }}
          >
            +1
          </Button>
          <Button
            disabled={!enableShotTime}
            onClick={() =>
              setShotTimeInitial((previousValue) => previousValue + 10)
            }
          >
            +10
          </Button>
        </div>
      </div>
    );
  };

  const [player1Name, setPlayer1Name] = useState<string>("Player 1");
  const [player2Name, setPlayer2Name] = useState<string>("Player 2");

  const mapPlayerNames = () => {
    return (
      <div className="PoolTimerInitializer-OuterContainer shadow">
        <div className="PoolTimerInitializer-SpacingRow">
          <div className="fw-bold me-2">Player Names</div>
        </div>

        <hr />

        <CustomInput
          label="Player 1 name"
          value={player1Name}
          onChange={setPlayer1Name}
          onClear={() => setPlayer1Name("")}
          error={player1Name.length === 0}
          helperText={player1Name === "" ? "Enter a name" : ""}
          className="w-100"
        />

        <CustomInput
          label="Player 2 name"
          value={player2Name}
          onChange={setPlayer2Name}
          onClear={() => setPlayer2Name("")}
          error={player2Name.length === 0}
          helperText={player2Name === "" ? "Enter a name" : ""}
          className="w-100"
        />
      </div>
    );
  };

  const ExtensionSlotProps = {
    input: {
      min: 1,
      max: 500,
      step: 1,
    },
  };

  const mapExtensionsFields = () => {
    return (
      <div
        className={`PoolTimerInitializer-OuterContainer shadow ${
          !enableExtension && "wrapper-disabled"
        }`}
      >
        <div className="PoolTimerInitializer-header">
          <div className="PoolTimerInitializer-SpacingRow">
            <div className="fw-bold me-2">Time Extensions</div>
          </div>
          <div className="PoolTimerInitializer-SpacingRow">
            <Form>
              <Form.Check
                type="switch"
                className="custom-switch"
                id="custom-switch"
                checked={enableExtension}
                onChange={() => setEnableExtension(!enableExtension)}
              />
            </Form>
          </div>
        </div>

        <hr />

        <label htmlFor="ExtensionTime">Extension Time &#40;seconds&#41; </label>
        <div className="PoolTimerInitializer-SpacingRow">
          <Button
            disabled={!enableExtension}
            onClick={() => {
              if (extensionTimeInitial > 10)
                setExtensionTimeInitial((previousValue) => previousValue - 10);
            }}
          >
            -10
          </Button>
          <Button
            disabled={!enableExtension}
            type="button"
            onClick={() => {
              if (extensionTimeInitial > 1)
                setExtensionTimeInitial((previousValue) => previousValue - 1);
            }}
          >
            -1
          </Button>

          <input
            disabled={!enableExtension}
            type="number"
            pattern="[0-9]*"
            id={"ExtensionTime"}
            value={extensionTimeInitial}
            className="PoolTimerInitializer-Input"
            onChange={doNothing}
          />
          <Button
            disabled={!enableExtension}
            type="button"
            onClick={() => {
              if (extensionTimeInitial < 50)
                setExtensionTimeInitial((previousValue) => previousValue + 1);
            }}
          >
            +1
          </Button>
          <Button
            disabled={!enableExtension}
            type="button"
            onClick={() => setExtensionTimeInitial(extensionTimeInitial + 10)}
          >
            +10
          </Button>
        </div>

        <hr />

        <label htmlFor="extensionLimit">Extensions per player </label>
        <div className="PoolTimerInitializer-SpacingRow">
          <Button
            disabled={!enableExtension}
            type="button"
            onClick={() => {
              if (extensionLimit > 1) setExtensionLimit(extensionLimit - 1);
            }}
          >
            -1
          </Button>

          <input
            disabled={!enableExtension}
            type="number"
            pattern="[0-9]*"
            id={"extensionLimit"}
            value={extensionLimit}
            className="PoolTimerInitializer-Input"
            onChange={doNothing}
          />
          <Button
            disabled={!enableExtension}
            type="button"
            onClick={() => setExtensionLimit(extensionLimit + 1)}
          >
            +1
          </Button>
        </div>
      </div>
    );
  };

  const decreaseTimeOutSeconds = () => {
    if (timeOutMinutes > 0 && timeOutSeconds >= 10)
      setTimeOutSeconds(timeOutSeconds - 10);
  };

  const mapTimeoutFields = () => {
    const increaseTimeOutSeconds = () => {
      if (timeOutSeconds < 50) setTimeOutSeconds(timeOutSeconds + 10);
    };

    return (
      <div
        className={`PoolTimerInitializer-OuterContainer shadow ${
          !enableTimeOut && "wrapper-disabled"
        }`}
      >
        <div className="PoolTimerInitializer-header">
          <div className="PoolTimerInitializer-SpacingRow">
            <div className="fw-bold me-2">Time-Out counter</div>
          </div>
          <div className="PoolTimerInitializer-SpacingRow">
            <Form>
              <Form.Check
                type="switch"
                className="custom-switch"
                id="custom-switch"
                checked={enableTimeOut}
                onChange={() => setEnableTimeOut(!enableTimeOut)}
              />
            </Form>
          </div>
        </div>

        <hr />

        <div className="PoolTimerInitializer-SpacingRow">
          <div className="PoolTimerInitializer-Column">
            <label htmlFor="TimeOutMins">Minutes</label>
            <Button
              disabled={!enableTimeOut}
              type="button"
              onClick={() => {
                if (timeOutMinutes < 50)
                  setTimeOutMinutes((previousValue) => previousValue + 1);
              }}
            >
              +1
            </Button>

            <input
              value={timeOutMinutes}
              disabled={!enableTimeOut}
              type="number"
              pattern="[0-9]*"
              id={"TimeOutMins"}
              className="PoolTimerInitializer-Input"
              onChange={doNothing}
            />

            <Button
              disabled={!enableTimeOut}
              type="button"
              onClick={() => {
                if (timeOutMinutes > 1)
                  setTimeOutMinutes((previousValue) => previousValue - 1);
              }}
            >
              -1
            </Button>
          </div>
          <div className="PoolTimerInitializer-Column">
            <label htmlFor="TimeOutMins">Seconds</label>
            <Button
              disabled={!enableTimeOut}
              type="button"
              onClick={() => {
                increaseTimeOutSeconds();
              }}
            >
              +10
            </Button>

            <input
              disabled={!enableTimeOut}
              type="number"
              pattern="[0-9]*"
              id={"TimeOutMins"}
              value={timeOutSeconds}
              className="PoolTimerInitializer-Input"
              onChange={doNothing}
            />

            <Button
              disabled={!enableTimeOut}
              type="button"
              onClick={() => {
                decreaseTimeOutSeconds();
              }}
            >
              -10
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const mapFoulsFields = () => {
    return (
      <div
        className={`PoolTimerInitializer-OuterContainer shadow ${
          !enableFouls && "wrapper-disabled"
        }`}
      >
        <div className="PoolTimerInitializer-header">
          <div className="PoolTimerInitializer-SpacingRow">
            <div className="fw-bold me-2">Fouls counter</div>
          </div>
          <div className="PoolTimerInitializer-SpacingRow">
            <Form>
              <Form.Check
                type="switch"
                className="custom-switch"
                id="custom-switch"
                checked={enableFouls}
                onChange={() => setEnableFouls(!enableFouls)}
              />
            </Form>
          </div>
        </div>

        <hr />

        <label htmlFor="foulsLimit">Maximum Fouls </label>

        <div className="PoolTimerInitializer-SpacingRow">
          <Button
            disabled={!enableFouls}
            type="button"
            onClick={() => {
              if (foulsLimit > 1) setFoulsLimit(foulsLimit - 1);
            }}
          >
            -1
          </Button>

          <input
            disabled={!enableFouls}
            type="number"
            pattern="[0-9]*"
            id={"foulsLimit"}
            value={foulsLimit}
            className="PoolTimerInitializer-Input"
            onChange={doNothing}
          />
          <Button
            disabled={!enableFouls}
            type="button"
            onClick={() => setFoulsLimit(foulsLimit + 1)}
          >
            +1
          </Button>
        </div>
      </div>
    );
  };

  const mapScoreFields = () => {
    return (
      <div
        className={`PoolTimerInitializer-OuterContainer shadow ${
          !enableScore && "wrapper-disabled"
        }`}
      >
        <div className="PoolTimerInitializer-header">
          <div className="PoolTimerInitializer-SpacingRow">
            <div className="fw-bold me-2">Score counter</div>
          </div>
          <div className="PoolTimerInitializer-SpacingRow">
            <Form>
              <Form.Check
                type="switch"
                className="custom-switch"
                id="custom-switch"
                checked={enableScore}
                onChange={() => setEnableScore(!enableScore)}
              />
            </Form>
          </div>
        </div>

        <hr />

        <label htmlFor="foulsLimit">Winning Score </label>

        <div className="PoolTimerInitializer-SpacingRow">
          <Button
            disabled={!enableScore}
            type="button"
            onClick={() => {
              if (scoreLimit > 1) setScoreLimit(scoreLimit - 1);
            }}
          >
            -1
          </Button>

          <input
            disabled={!enableScore}
            type="number"
            pattern="[0-9]*"
            id={"scoreLimit"}
            value={scoreLimit}
            className="PoolTimerInitializer-Input"
            onChange={doNothing}
          />
          <Button
            disabled={!enableScore}
            type="button"
            onClick={() => setScoreLimit(scoreLimit + 1)}
          >
            +1
          </Button>
        </div>
      </div>
    );
  };

  const toggleTimer = () => {
    setStartTimer(!startTimer);
  };

  return (
    <div className="PoolTimerInitializer-ContainerColumn">
      {!startTimer ? (
        <>
          <h5 className="text-center mt-2 mb-2">
            Select the settings for your pool timer below and click{" "}
            <strong>Start</strong> to begin.
          </h5>
          <h5 className="text-center mt-2 mb-4">
            You can return to this screen at any time by clicking the home icon
          </h5>

          <Button
            onClick={toggleTimer}
            variant="success"
            className="fw-bold pe-3 ps-3"
            size="lg"
          >
            Start
          </Button>

          <div className="PoolTimerInitializer-Wrapper">
            <div className="PoolTimerInitializer-Spacing">
              {mapPlayerNames()}
            </div>
            <div className="PoolTimerInitializer-Spacing">
              {mapTimeFields()}
            </div>
            <div className="PoolTimerInitializer-Spacing">
              {mapTimeoutFields()}
            </div>
            <div className="PoolTimerInitializer-Spacing">
              {mapExtensionsFields()}
            </div>
            <div className="PoolTimerInitializer-Spacing">
              {mapFoulsFields()}
            </div>
            <div className="PoolTimerInitializer-Spacing">
              {mapScoreFields()}
            </div>
          </div>
        </>
      ) : (
        <>
          <Button aria-label="exit timer" onClick={toggleTimer}>
            <HouseDoorFill />
          </Button>
          <TimeRenderer
            shotTimeInitial={shotTimeInitial}
            extensionTimeInitial={extensionTimeInitial}
            brakeTimeInitial={brakeTimeInitial}
            extensionLimit={extensionLimit}
            foulsLimit={foulsLimit}
            enableFouls={enableFouls}
            enableExtension={enableExtension}
            enableSCore={enableScore}
            scoreLimit={scoreLimit}
            player1Name={player1Name}
            player2Name={player2Name}
            timeOutMinutes={timeOutMinutes}
            timeOutSeconds={timeOutSeconds}
            enableTimeOut={enableTimeOut}
            enableShotTime={enableShotTime}
          />
        </>
      )}
    </div>
  );
};

export default PoolTimerInitializer;
