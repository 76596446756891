import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { inject } from "@vercel/analytics";

import DesktopNavbar from "./components/Navbar/DesktopNavbar";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import PoolTimerPage from "./pages/timer/pool-timer";
import TimerPage from "./pages/timer";

function Layout() {
  return (
    <>
      <DesktopNavbar />
      <Outlet />
    </>
  );
}

inject();

const App: React.FC = () => (
  <Fragment>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />

          <Route path="timer" element={<TimerPage />} />
          <Route path="timer/pool-timer" element={<PoolTimerPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Fragment>
);

export default App;
