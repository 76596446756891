import React from "react";

interface CardProps {
  path: string;
  title: string;
  footerText: string;
  imageUrl: string;
}

const TimerCard: React.FC<CardProps> = ({
  path,
  title,
  imageUrl,
  footerText,
}) => {
  return (
    <div className="card">
      <a href={path}>
        <div className="card-body">
          {/* <h5 className="card-title">{title}</h5> */}
          <img src={imageUrl} className="card-img-top" />
        </div>
      </a>
    </div>
  );
};

export default TimerCard;
