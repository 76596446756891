import React, { FC } from "react";
import Form from "react-bootstrap/Form";

interface CustomInputProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  onClear?: () => void;
  helperText?: string;
  error?: boolean;
  type?: string;
  className?: string;
}

const CustomInput: FC<CustomInputProps> = ({
  label,
  value,
  onChange,
  onClear,
  helperText,
  error,
  type = "text",
  className = "",
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <Form className={className}>
      <Form.Group className="mb-1 mt-2" controlId="formBasicEmail">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type={type}
          placeholder={`Enter...`}
          onChange={handleChange}
          value={value}
        />
        <Form.Text className="text-danger">{helperText}</Form.Text>
      </Form.Group>
    </Form>
  );
};

export default CustomInput;
