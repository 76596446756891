import React, {FC} from "react";

import PoolTimerInitializer from "../../components/Timers/PoolTimerInitializer";

const PoolTimerPage: FC = () => {
    return (
        <PoolTimerInitializer/>
    );
};

export default PoolTimerPage;
