import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";

import TimeOutTimer from "./TimeOutTimer";
import { Button } from "react-bootstrap";

import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import useFontSize from "../../hooks/useFontSize";
import useMobileSize from "../../hooks/useMobileSizes";

interface ProgressProps {
  width: number;
  backgroundColor: string;
}

interface TimeRendererProps {
  shotTimeInitial: number;
  extensionTimeInitial: number;
  brakeTimeInitial: number;
  extensionLimit: number;
  foulsLimit: number;
  enableFouls: boolean;
  enableExtension: boolean;
  enableSCore: boolean;
  scoreLimit: number;
  player1Name: string | null;
  player2Name: string | null;
  enableTimeOut: boolean;
  timeOutSeconds: number;
  timeOutMinutes: number;
  enableShotTime: boolean;
}

const TimeRenderer: FC<TimeRendererProps> = ({
  shotTimeInitial,
  extensionTimeInitial,
  brakeTimeInitial,
  extensionLimit,
  foulsLimit,
  enableFouls,
  enableExtension,
  enableSCore,
  scoreLimit,
  player1Name,
  player2Name,
  timeOutSeconds,
  timeOutMinutes,
  enableTimeOut,
  enableShotTime,
}) => {
  const [initialTime, setInitialTime] = useState<number>(brakeTimeInitial);

  const [foulsPlayer1, setFoulsPlayer1] = useState<number>(0);
  const [foulsPlayer2, setFoulsPlayer2] = useState<number>(0);
  const [extensionsPlayer1, setExtensionsPlayer1] = useState<number>(0);
  const [extensionsPlayer2, setExtensionsPlayer2] = useState<number>(0);

  const [scorePlayer1, setScorePlayer1] = useState<number>(0);
  const [scorePlayer2, setScorePlayer2] = useState<number>(0);

  const [remainingTime, setRemainingTime] = useState<number>(brakeTimeInitial);

  const { buttonPadding, iconSize, buttonSize, isDesktop } = useMobileSize();

  function getBackgroundColor() {
    if (minutes > 0 || (seconds > 5 && seconds > shotTimeInitial / 2))
      return "green";
    if (seconds > 5) return "orange";
    if (seconds > 0) return "red";
    return "black";
  }

  const shotTime = new Date();
  shotTime.setSeconds(shotTime.getSeconds() + initialTime);

  const { seconds, minutes, isRunning, start, pause, resume, restart } =
    useTimer({
      expiryTimestamp: shotTime,
      onExpire: () => {
        console.log("expired");
      },
    });

  interface BoardRowProps {
    player: number;
    limitColor: string;
    textMobile: string;
    textDesktop: string;
    count: number;
    countLimit: number;
    addCount: () => void;
    reduceCount: () => void;
    className?: string;
    rowDirection?: "row" | "row-reverse";
  }

  const BoardRow: FC<BoardRowProps> = ({
    player = 1,
    limitColor,
    textMobile,
    textDesktop,
    count = 1,
    countLimit = 1,
    addCount,
    reduceCount,
    className,
    rowDirection = "row",
  }) => {
    return (
      <div
        className={`timeRenderer-containerColumn shadow ${
          className && className
        }`}
        style={{
          backgroundColor: `${count >= countLimit ? limitColor : "none"}`,
          color: `${count >= countLimit ? "white" : "black"}`,
        }}
      >
        <span className="board-row-title">{textDesktop}</span>

        <div
          className="d-flex"
          style={{
            flexDirection: `${rowDirection}`,
          }}
        >
          <Button
            onClick={reduceCount}
            size={buttonSize}
            variant="danger"
            className={buttonPadding}
          >
            <DashCircle
              color="white"
              size={iconSize}
              className={buttonPadding}
            />
          </Button>

          <span className="counter-text">{count}</span>

          <Button
            onClick={addCount}
            size={buttonSize}
            variant="success"
            className={buttonPadding}
          >
            <PlusCircle color="white" size={iconSize} />
          </Button>
        </div>
      </div>
    );
  };

  const ScoreBoardRow: FC<BoardRowProps> = memo(
    ({
      player = 1,
      limitColor,
      textMobile,
      textDesktop,
      count = 1,
      countLimit = 1,
      addCount,
      reduceCount,
      className,
      rowDirection = "row",
    }) => {
      const { fontSize } = useFontSize();

      return (
        <div
          className="timeRenderer-containerColumn-scoreCard shadow pt-2"
          style={{
            color: `${count >= countLimit ? limitColor : "black"}`,
          }}
        >
          <div
            className="timeRenderer-scoreWrapper"
            style={{
              flexDirection: `${rowDirection}`,
            }}
          >
            <Button
              onClick={reduceCount}
              size={buttonSize}
              variant="danger"
              className={`${buttonPadding} ${
                textMobile === "SCORE" && !isDesktop ? "p5" : ""
              }`}
            >
              <DashCircle color="white" size={iconSize} />
            </Button>

            <Button
              onClick={addCount}
              size={buttonSize}
              variant="success"
              className={`${buttonPadding} ${
                textMobile === "SCORE" && !isDesktop ? "p5" : ""
              }`}
            >
              <PlusCircle color="white" size={iconSize} />
            </Button>
          </div>
          <div className="score-counter-text">
            <span
              className="fw-bold font-scoreCard"
              style={{ fontSize: `${fontSize}px` }}
            >
              {count}
            </span>
          </div>
        </div>
      );
    }
  );

  interface PlayerProp {
    player: number;
  }

  const ScoreCard: FC<PlayerProp> = memo(({ player = 1 }) => {
    const score = player === 1 ? scorePlayer1 : scorePlayer2;
    const setScore = player === 1 ? setScorePlayer1 : setScorePlayer2;

    const addScore = () => {
      if (score < scoreLimit) setScore(score + 1);
    };

    const removeScore = () => {
      if (score > 0) setScore(score - 1);
    };

    return (
      <ScoreBoardRow
        limitColor="red"
        textMobile="SCORE"
        textDesktop="SCORE"
        player={player}
        count={score}
        countLimit={scoreLimit}
        addCount={addScore}
        reduceCount={removeScore}
        rowDirection={"row"}
      />
    );
  });

  const FoulsCard: FC<PlayerProp> = memo(({ player = 1 }) => {
    const fouls = player === 1 ? foulsPlayer1 : foulsPlayer2;
    const setFouls = player === 1 ? setFoulsPlayer1 : setFoulsPlayer2;

    const addFoul = () => {
      if (fouls < foulsLimit) setFouls(fouls + 1);
    };

    const removeFoul = () => {
      if (fouls > 0) setFouls(fouls - 1);
    };

    return (
      <BoardRow
        limitColor="red"
        textMobile="FOULS"
        textDesktop="FOULS"
        player={player}
        count={fouls}
        countLimit={foulsLimit}
        addCount={addFoul}
        reduceCount={removeFoul}
        className="space-between"
        rowDirection={player === 1 ? "row" : "row-reverse"}
      />
    );
  });

  const ExtCard: FC<PlayerProp> = memo(({ player = 1 }) => {
    const extensions = player === 1 ? extensionsPlayer1 : extensionsPlayer2;
    const setExtensions =
      player === 1 ? setExtensionsPlayer1 : setExtensionsPlayer2;

    const addExtension = () => {
      if (extensions < extensionLimit) {
        setExtensions(extensions + 1);
        extendShotTimer();
      }
    };

    const removeExtension = () => {
      if (extensions > 0) setExtensions(extensions - 1);
    };

    return (
      <BoardRow
        limitColor="red"
        textMobile="EXTEN."
        textDesktop="EXTENSIONS"
        player={player}
        count={extensions}
        countLimit={extensionLimit}
        addCount={addExtension}
        reduceCount={removeExtension}
        className="space-between"
        rowDirection={player === 1 ? "row" : "row-reverse"}
      />
    );
  });

  const getBgStyle = () => {
    return {
      background: `${getBackgroundColor()}`,
      maxWidth: "100%",
      width: `${
        minutes > 0 || seconds > shotTimeInitial
          ? 100
          : (seconds / shotTimeInitial) * 100
      }%`,
    };
  };

  const timeWithSeconds = `${seconds > 9 ? seconds : `0${seconds}`}`;

  const timeWithMinutes =
    minutes > 0 ? `${minutes} : ${timeWithSeconds}` : timeWithSeconds;

  const [status, setPaused] = useState<boolean>(false);

  const handlePause = () => {
    if (status) {
      resume();
      setPaused(false);
    } else {
      pause();
      setPaused(true);
    }
  };

  function restartShotTimer() {
    const time = new Date();
    time.setSeconds(time.getSeconds() + shotTimeInitial);
    restart(time);
    if (status) setPaused(false);
  }

  function extendShotTimer() {
    const time = new Date();
    time.setSeconds(
      time.getSeconds() + extensionTimeInitial + seconds + minutes * 60
    );
    restart(time);
    if (status) setPaused(false);
  }

  function renewExtensions() {
    if (extensionsPlayer1 !== 0) setExtensionsPlayer1(0);
    if (extensionsPlayer2 !== 0) setExtensionsPlayer2(0);
  }

  function startNew() {
    const time = new Date();
    time.setSeconds(time.getSeconds() + brakeTimeInitial);
    renewExtensions();
    if (status) setPaused(false);
    if (foulsPlayer1 !== 0) setFoulsPlayer1(0);
    if (foulsPlayer2 !== 0) setFoulsPlayer2(0);

    restart(time);
  }

  const playPauseText = status ? "Resume" : "Pause";

  const player1foul = () => {
    if (foulsPlayer1 < foulsLimit) {
      setFoulsPlayer1(foulsPlayer1 + 1);
    }
  };

  const player2foul = () => {
    if (foulsPlayer2 < foulsLimit) {
      setFoulsPlayer2(foulsPlayer2 + 1);
    }
  };

  const player1extension = () => {
    if (extensionsPlayer1 < extensionLimit) {
      setExtensionsPlayer1(extensionsPlayer1 + 1);
      const time = new Date();
      time.setSeconds(
        time.getSeconds() + extensionTimeInitial + seconds + minutes * 60
      );

      restart(time);

      if (status) setPaused(false);
    }
  };

  const player2extension = () => {
    if (extensionsPlayer2 < extensionLimit) {
      setExtensionsPlayer2(extensionsPlayer2 + 1);
      const time = new Date();
      time.setSeconds(
        time.getSeconds() + extensionTimeInitial + seconds + minutes * 60
      );

      restart(time);

      if (status) setPaused(false);
    }
  };

  const Player1Infos = memo(() => {
    return (
      <div className="timeRenderer-playerInfo shadow">
        <h5 className="fw-bold text-center">
          {player1Name && player1Name.length > 0 ? player1Name : "Player 1"}
        </h5>
        <hr />

        <div className="timeRenderer-cell">
          <div className="column-around">
            {enableTimeOut && (
              <TimeOutTimer
                mins={timeOutMinutes}
                secs={timeOutSeconds}
                player={1}
              />
            )}
            {enableExtension && <ExtCard player={1} />}
            {enableFouls && <FoulsCard player={1} />}
          </div>
          {enableSCore && <ScoreCard player={1} />}
        </div>
      </div>
    );
  });

  const Player2Infos = memo(() => {
    return (
      <div className="timeRenderer-playerInfo shadow">
        <h5 className="fw-bold text-center">
          {player2Name && player2Name?.length > 0 ? player2Name : "Player 2"}
        </h5>
        <hr />

        <div className="timeRenderer-cell">
          {enableSCore && <ScoreCard player={2} />}
          <div className="column-around">
            {enableTimeOut && (
              <TimeOutTimer
                mins={timeOutMinutes}
                secs={timeOutSeconds}
                player={2}
              />
            )}
            {enableExtension && <ExtCard player={2} />}
            {enableFouls && <FoulsCard player={2} />}
          </div>
        </div>
      </div>
    );
  });

  const mainButtons = () => {
    return (
      <div className="timeRenderer-cell">
        <Button onClick={startNew} size="sm">
          BREAK
        </Button>

        <Button onClick={handlePause}>{playPauseText.toUpperCase()}</Button>

        <Button onClick={restartShotTimer}>NEW SHOT</Button>
      </div>
    );
  };

  const MainButtonsInner = memo(() => {
    return (
      <div className="timeRenderer-rowCell">
        <Button className="timeRenderer-adjustButtonInner" onClick={startNew}>
          BREAK
        </Button>

        <Button
          className="timeRenderer-adjustButtonInner"
          onClick={handlePause}
        >
          {playPauseText.toUpperCase()}
        </Button>

        <Button
          onClick={restartShotTimer}
          className="timeRenderer-adjustButtonInner"
        >
          NEW SHOT
        </Button>
      </div>
    );
  });

  const [fontSize, setFontSize] = useState("15rem)");

  const handleFontSize = () => {
    {
      if (minutes > 0) setFontSize("min(calc(4rem + 15vw), 10rem)");
      else setFontSize("min(calc(5rem + 25vw), 15rem)");
    }
  };

  useEffect(() => {
    handleFontSize();
  }, []);

  useEffect(() => {
    handleFontSize();
  }, [minutes]);

  return (
    <>
      <hr />
      <div className="timeRenderer-buttonsContainer">
        {(enableExtension || enableFouls || enableSCore || enableTimeOut) && (
          <Player1Infos />
        )}

        {(enableExtension || enableFouls || enableSCore || enableTimeOut) && (
          <Player2Infos />
        )}
      </div>

      {enableShotTime && (
        <div className="timeRenderer-containerColumn" style={getBgStyle()}>
          {<MainButtonsInner />}
          {minutes > 0 ? (
            <div
              className="timeRenderer-timerText"
              style={{ fontSize: `${fontSize}` }}
            >
              {minutes}:{seconds > 9 ? seconds : `0${seconds}`}
            </div>
          ) : (
            <div
              className="timeRenderer-timerText"
              style={{ fontSize: `${fontSize}` }}
            >
              {seconds}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TimeRenderer;
