import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import logo from "./svg/logo.png";
export const navLinks = [
  { name: "GAME BOARD", path: "/timer" },
  { name: "CALENDAR", path: "/calendar" },
  { name: "@", path: "/contact" },
  { name: "ABOUT", path: "/about" },
];

function DesktopNavbar() {
  return (
    <Navbar expand="lg" bg="secondary" data-bs-theme="dark" className="p-1">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="logo" height="40" />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="color-white"
        />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="d-flex">
            {navLinks.map((link) => (
              <Nav.Link
                key={link.path}
                href={link.path}
                className="text-white ms-3 fw-bold rounded navLink px-3 fw-bold"
              >
                {link.name}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default DesktopNavbar;
